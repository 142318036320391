import request from "@/utils/request";

// 清空搜索记录（全部删除传空数组或者null)
export function deleteHis(data) {
    return request({
        url: '/imageHis/deleteHis',
        method: 'post',
        data
    })
}
// 查询搜索记录
export function pageHis(data) {
    return request({
        url: '/imageHis/pageHis',
        method: 'post',
        data
    })
}