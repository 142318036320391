import request from "@/utils/request";

// 获取用户信息
export function getUserInfo(data) {
    return request({
        url: '/usercenter/getUserInfo',
        method: 'get',
        params:data
    })
}
// 获取用户信息
export function logout(data) {
    return request({
        url: '/usercenter/logout',
        method: 'post',
        data
    })
}