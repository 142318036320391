import Cookies from 'js-cookie'

const TokenKey = 'vatt'
const SpIdKey = 'spdKey'
const TicketKey = 'Tic'
const UserHashKey = 'uKey'

export function getTicket() {
    return Cookies.get(TicketKey)
}

export function setTicket(token) {
    return Cookies.set(TicketKey, token)
}

export function removeTicket() {
    return Cookies.remove(TicketKey)
}

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}   