import request from "@/utils/request";

// 上传图片到oss
export function uploadImageToOSS(data) {
    return request({
        url: '/intellectual/uploadImageToOSS',
        method: 'post',
        data
    })
}


// 筛选条件初始化-分类
export function initCategory(data) {
    return request({
        url: '/common/initCategory',
        method: 'post',
        data
    })
}
// 上传压缩文件到oss
export function uploadFileToOSS(data) {
    return request({
        url: '/common/uploadFileToOSS',
        method: 'post',
        data
    })
}

