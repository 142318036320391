import request from "@/utils/request";

// 阿里图片搜索
export function listSimilarProductV2(data) {
    return request({
        url: '/intellectual/listSimilarProductV2',
        method: 'post',
        data,
    })
}
// 专利号片搜索
export function search(data) {
    return request({
        url: '/intellectual/searchV2',
        method: 'post',
        data,
    })
}
//专利详情
export function detail(data) {
    return request({
        url: '/intellectual/detail',
        method: 'post',
        data,
    })
}

//首页轮播图诉讼
export function caseHome(data) {
    return request({
        url: '/intellectual/case/listHome',
        method: 'post',
        data,
    })
}
//诉讼列表
export function caseList(data) {
    return request({
        url: '/intellectual/case/list',
        method: 'post',
        data,
    })
}
//诉讼详情
export function caseInfo(data) {
    return request({
        url: '/intellectual/case/info',
        method: 'post',
        data,
    })
}
export function listSimilarProductV2ByPic(data) {
    return request({
        url: '/intellectual/listSimilarProductV2ByPic',
        method: 'post',
        data,
    })
}
export function queryAbstractImage(data) {
    return request({
        url: '/intellectual/queryAbstractImage  ',
        method: 'post',
        data,
    })
}
export function patentCountry(data) {
    return request({
        url: '/intellectual/patentCountry/getContry',
        method: 'post',
        data,
    })
}

export function getLocList(data) {
    return request({
        url: '/intellectual/locInfo/getLocList',
        method: 'post',
        data,
    })
}
